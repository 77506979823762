import React, { useState, useEffect } from 'react'
import { PrismicRichText } from '@prismicio/react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { formatDate, formatDuration } from '../util/timeDateUtils'

import { MediaIcon } from '../components/ContentBlocks/MediaLibraryCommon'

import SEO from '../components/SEO'
// import GoogleAds from '../components/GoogleAds'
import Layout from '../components/Layout'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'

import { getGlobal } from '../util/global'
import { DISPLAY_WIDTH } from '../util/constants'

import PodcastBlock from '../components/ContentBlocks/PodcastBlock'
import PodcastDirectoryBlock from '../components/ContentBlocks/PodcastDirectoryBlock'
import VideoBlock from '../components/ContentBlocks/VideoBlock'
import { RegionContainer } from '../components/styles/Utility.styles'
import WebinarPresentersList from '../components/ContentBlocks/WebinarPresentersList'
import CtaLink from '../components/ContentBlocks/CtaLink'
import SocialSharingBlock from '../components/ContentBlocks/SocialSharingBlock'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import MediaLibraryItemPageRelatedContent from '../components/ContentBlocks/MediaLibraryItemPageRelatedContent'
import { HcpdBackArrow } from '../components/Icons'
import * as Styled from '../components/Heroes/styles/MediaItemPageHero.styles'
import { sliceBottomPadding } from '../util/helper'
import AdHead from '../components/AdHead'
import AdSlot from '../components/AdSlot'

export const Head = ({ pageContext }) => {
  return (
    <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
  )
}

const BackButton = () => (
  <Styled.BackButtonContainer to="/watch-and-listen/">
    <HcpdBackArrow />
    <span>Media for Patients</span>
  </Styled.BackButtonContainer>
)

const InjectSchema = ({ node }) => {
  switch (node.media_type?.document?.data?.type) {
    case 'Podcast': {
      const podcastBlockNode = node.body
        .filter(media => media.slice_type === 'podcast')
        .slice(0, 1)
        .pop().primary
      return (
        // <pre>{JSON.stringify(podcastBlockNode, false, 2)}</pre>
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "AudioObject",
              "description": "${
                podcastBlockNode.schema_podcast_description
                  ? podcastBlockNode.schema_podcast_description
                  : ''
              }",
              "duration": "${formatDuration(
                podcastBlockNode.podcast_duration_hours,
                podcastBlockNode.podcast_duration_minutes,
                podcastBlockNode.podcast_duration_seconds
              )}",
              "uploaddate": "${
                podcastBlockNode.podcast_episode_date
                  ? podcastBlockNode.podcast_episode_date.replace(/-/g, '')
                  : ''
              }",
              "name": "${node.title?.text ?? ''}",
              "publisher": {
                "@type": "Organization",
                "name": "National Psoriasis Foundation",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://www.psoriasis.org/sites/all/themes/national_psoriasis_foundation/logo.png"
                }
              },
              "thumbnailUrl": "${node.hero_image?.url ?? ''}"
            }
          `}
          </script>
        </Helmet>
      )
    }
    // case 'Webinar':
    //   return null
    // case 'Video':
    //   // we're already doing this in the Video Block element, so skip it here.
    //   return null
    default:
      return null
  }
}

const UpcomingWebinarTimeField = ({ data }) => {
  const [eventTimeString, setEventTimeString] = useState(null)
  useEffect(() => {
    setEventTimeString(
      data.body
        ?.filter(node => node.slice_type === 'upcoming_webinar')
        ?.slice(0, 1)
        ?.pop()?.primary?.event_time ?? null
    )
  }, [data])

  return (
    <>
      {eventTimeString && (
        <Styled.MediaItemDate>
          {eventTimeString} /{' '}
          <a href="https://www.timeanddate.com/worldclock/converter.html">
            Time Zone Converter
          </a>
        </Styled.MediaItemDate>
      )}
    </>
  )
}

const MediaItemPageHero = ({ data, doc, siteSettings }) => {
  const [isUpcoming, setIsUpcoming] = useState(false)

  useEffect(() => {
    if (!data.datestamp) {
      return null
    }
    const today = new Date()
    const eventDate = new Date(data.datestamp)
    if (eventDate >= today) {
      setIsUpcoming(true)
    }
  }, [data])

  return (
    <Styled.HeroContainer>
      <Styled.LogoTopicDateRegion>
        <MediaIcon type={data.media_type?.document?.data?.type} />
        <Styled.TopicAndDateBox>
          <Styled.Topic>
            {data.topic?.document?.data?.topic}
            {data.show_cme_tag !== false && <span>&nbsp;*CME</span>}
          </Styled.Topic>
          <Styled.MediaItemDate>
            {formatDate(data.datestamp)}
          </Styled.MediaItemDate>
          {/* {data.media_type?.document?.data?.type === 'Webinar' &&
            isUpcoming && <UpcomingWebinarTimeField data={data} />} */}
          <UpcomingWebinarTimeField data={data} />
        </Styled.TopicAndDateBox>
      </Styled.LogoTopicDateRegion>
      <Styled.TextRegion>
        <Styled.MediaItemTitle>
          {/* {data.title ? RichText.render(data.title, linkResolver ) : ''} */}
          {data.title?.richText ? (
            <PrismicRichText field={data.title.richText} />
          ) : (
            ''
          )}
        </Styled.MediaItemTitle>
        <MediaItem node={doc} npfLogo={siteSettings.npf_logo?.url} />
        {doc.media_type?.document?.data?.type !== 'Podcast' && (
          <Styled.MediaItemDescription>
            {data.body_text?.richText ? (
              <PrismicRichText field={data.body_text.richText} />
            ) : (
              ''
            )}
            {/* {data.body_text ? RichText.render(data.body_text, linkResolver ) : ''} */}
          </Styled.MediaItemDescription>
        )}
        {doc.media_type?.document?.data?.type === 'Podcast' && (
          <Styled.MediaItemDescription>
            {data.body_text?.richText ? (
              <PrismicRichText field={data.body_text.richText} />
            ) : (
              ''
            )}
          </Styled.MediaItemDescription>
        )}
      </Styled.TextRegion>
    </Styled.HeroContainer>
  )
}

const MediaItem = ({ node, npfLogo }) => {
  switch (node.media_type?.document?.data?.type) {
    case 'Podcast': {
      const podcastNode = node.body
        ?.filter(block => block.slice_type === 'podcast')
        ?.slice(0, 1)
        .pop()
      const webinarNode =
        node.body
          ?.filter(block => block.slice_type === 'upcoming_webinar')
          ?.slice(0, 1)
          .pop() ?? null
      if (!podcastNode) {
        return null
      }
      return (
        <>
          <PodcastBlock data={podcastNode.primary} />
          {webinarNode && webinarNode?.primary?.registration_link && (
            <RegionContainer>
              <div style={{ gridColumn: '2 / 3', paddingBottom: '40px' }}>
                <CtaLink
                  text="Register Now"
                  link={webinarNode.primary.registration_link}
                />
              </div>
            </RegionContainer>
          )}

          <WebinarPresentersList
            presenters={
              node.body
                ?.filter(block => block.slice_type === 'webinar_presenter_s_')
                ?.slice(0, 1)
                ?.pop()?.items ?? null
            }
          />
        </>
      )
    }
    case 'Webinar':
      {
        // Webinars are tricky because they can be either video_block elements or upcoming_webinar elements
        const webinarNode = node.body
          ?.filter(block => block.slice_type === 'upcoming_webinar')
          ?.slice(0, 1)
          .pop()
        const webinarVideoNode = node.body
          ?.filter(block => block.slice_type === 'video_block')
          ?.slice(0, 1)
          .pop()
        if (!webinarNode && !webinarVideoNode) {
          return null
        }
        if (webinarNode) {
          const webinarNodeBottomPaddingAdjustment = sliceBottomPadding(
            webinarNode?.primary?.bottom_padding_adjustment
          )
          return (
            <>
              <RegionContainer>
                <div style={{ gridColumn: '2 / 3', paddingBottom: '40px' }}>
                  <CtaLink
                    text="Register Now"
                    link={webinarNode.primary.registration_link}
                  />
                </div>
              </RegionContainer>
              <WebinarPresentersList
                presenters={
                  node.body
                    ?.filter(
                      block => block.slice_type === 'webinar_presenter_s_'
                    )
                    ?.slice(0, 1)
                    ?.pop()?.items ?? null
                }
              />
              {/* add disclaimer */}
            </>
          )
        } else if (webinarVideoNode) {
          const webinarVideoNodeBottomPaddingAdjustment = sliceBottomPadding(
            webinarVideoNode?.primary?.bottom_padding_adjustment
          )
          return (
            <>
              <VideoBlock
                data={webinarVideoNode.primary}
                bottomPaddingAdjustment={
                  webinarVideoNodeBottomPaddingAdjustment
                }
              />
              <WebinarPresentersList
                presenters={
                  node.body
                    ?.filter(
                      block => block.slice_type === 'webinar_presenter_s_'
                    )
                    ?.slice(0, 1)
                    ?.pop()?.items ?? null
                }
              />
            </>
          )
        }
      }
      break
    // return <pre>{JSON.stringify(node)}</pre>
    case 'Video': {
      const videoNode = node.body
        ?.filter(block => block.slice_type === 'video_block')
        ?.slice(0, 1)
        .pop()
      if (!videoNode) {
        return null
      }
      const bottomPaddingAdjustment = sliceBottomPadding(
        videoNode?.primary?.bottom_padding_adjustment
      )
      return (
        <VideoBlock
          data={{ ...videoNode.primary, npfLogo: npfLogo }}
          bottomPaddingAdjustment={bottomPaddingAdjustment}
        />
      )
    }
    default:
      return null
  }
}

const MediaLibraryItemPage = ({ data }) => {
  const global = getGlobal()
  const href = global?.location?.href
  const siteSettings = data.prismicGeneralSiteSettings.data
  const doc = data.prismicMediaLibraryItem.data
  if (!doc) {
    return null
  }
  const twitterImage = doc.twitter_image?.url
    ? doc.twitter_image
    : doc.hero_image?.url
    ? doc.hero_image
    : null
  const facebookImage = doc.facebook_image?.url
    ? doc.facebook_image
    : doc.hero_image?.url
    ? doc.hero_image
    : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : null
  const bottomLineAdSlot =
    siteSettings.media_library_item_google_ad?.document?.data.ad_slot_name

  const seo_embed_code = doc.seo_embed_code?.text ?? null

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <InjectSchema node={doc} />
      {/* <pre>{JSON.stringify(doc, false, 2)}</pre> */}
      <Layout>
        <RegionContainer>
          <BackButton />
        </RegionContainer>
        <div className="two-column-layout" data-layout="two-column">
          <div className="two-column-layout__wrapper">
            <div className="two-column-layout__main">
              <MediaItemPageHero
                data={doc}
                doc={doc}
                siteSettings={siteSettings}
              />
            </div>
            <div className="two-column-layout__aside">
              <div className="util-large-only">
                <PodcastDirectoryBlock />
              </div>
            </div>
          </div>
        </div>
        {
          // Don't render hero image for Video pages
          doc.hero_image &&
            doc.media_type?.document?.data?.type !== 'Video' &&
            doc.media_type?.document?.data?.type !== 'Podcast' &&
            doc.media_type?.document?.data?.type !== 'Webinar' && (
              <RegionContainer>
                <Styled.HeroImage>
                  <source
                    media={`(max-width: ${DISPLAY_WIDTH.tabletL - 1}px)`}
                    srcSet={doc.hero_image.thumbnails.mobile.url}
                  />
                  <source
                    media={`(min-width: ${DISPLAY_WIDTH.tabletL}px)`}
                    srcSet={doc.hero_image.url}
                  />
                  <img src={doc.hero_image.url} alt={doc.hero_image.alt} />
                </Styled.HeroImage>
              </RegionContainer>
            )
        }
        {doc.body &&
          doc.body
            .filter(
              block =>
                block.slice_type !== 'podcast' &&
                block.slice_type !== 'video_block' &&
                block.slice_type !== 'upcoming_webinar'
            )
            .map(block => <SliceRenderer key={block.id} block={block} />)}
        <SocialSharingBlock
          title={seoTitle}
          description={doc.meta_description}
          url={href}
        />
        <MediaLibraryItemPageRelatedContent
          type={doc.media_type.document.data.type}
          activeItem={doc.uid}
        />
        <div className="util-small-only">
          <PodcastDirectoryBlock />
        </div>
        {bottomLineAdSlot && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdSlot}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query MediaLibraryItemPageQuery($id: String) {
    prismicGeneralSiteSettings {
      data {
        npf_logo {
          alt
          url
        }
        webinar_disclaimer_text {
          text
          richText
        }
        media_library_item_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
    prismicMediaLibraryItem(id: { eq: $id }) {
      id
      data {
        datestamp
        show_cme_tag
        content_expiration_date
        display_name
        facebook_image {
          url
        }
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        meta_description
        twitter_image {
          url
        }
        title {
          richText
          text
        }
        body_text {
          richText
        }
        hero_image {
          alt
          url
          thumbnails {
            library_hero_desktop {
              url
            }
            library_hero_mobile {
              url
            }
            library_thumbnail {
              url
            }
            mobile {
              url
            }
          }
        }
        topic {
          id
          uid
          type
          link_type
          document {
            ... on PrismicMediaLibraryItemTopic {
              data {
                topic
              }
            }
          }
        }
        media_type {
          id
          uid
          type
          link_type
          document {
            ... on PrismicMediaLibraryItemType {
              data {
                type
              }
            }
          }
        }
        body {
          ... on PrismicMediaLibraryItemDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicMediaLibraryItemDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicMediaLibraryItemDataBodyPodcast {
            id
            slice_type
            primary {
              podcast_duration_hours
              podcast_duration_minutes
              podcast_duration_seconds
              podcast_episode_date
              schema_podcast_description
              transcription_link {
                url
                uid
                type
                link_type
                id
              }
              embed_code {
                text
              }
              next_podcast {
                url
                uid
                type
                link_type
                id
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicMediaLibraryItemDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicMediaLibraryItemDataBodyUpcomingWebinar {
            id
            slice_type
            primary {
              event_time
              registration_link {
                id
                link_type
                type
                uid
                url
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicMediaLibraryItemDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
          ... on PrismicMediaLibraryItemDataBodyWebinarPresenterS {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              presenter_name
              presenter_title
              presenter_type
              presenter_image {
                url
                alt
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MediaLibraryItemPage
